import { ApplyServiceName } from "./serviceName";

export const AppRoutes = {
  HOME_PAGE: ApplyServiceName("/"),
  LOGIN_PAGE: ApplyServiceName("/login"),
  SETTINGS_PAGE: ApplyServiceName("/settings"),
  EVENT_MAP_PAGE: ApplyServiceName("/event-map"),
  MY_PURCHASES_PAGE: ApplyServiceName("/my-purchases"),
  REGISTRATION_PAGE: ApplyServiceName("/registration"),
  RESET_PASSWORD: ApplyServiceName("/resetpasswordrequest"),
  FORGOT_PASSWORD: ApplyServiceName("/forgotpassword"),
  EMAIL_CONFIRMATION_PAGE: ApplyServiceName("/confirmaccount"),
  RESEND_EMAIL_CONFIRMATION_PAGE: ApplyServiceName("/resendemail"),
  WORKGROUP_PICK_PAGE: ApplyServiceName("/workgroups"),
  CONTACT_US_PAGE: ApplyServiceName("/contactus"),
  STORE_PAGE: ApplyServiceName("/store"),
  ACCEPT_INVITATION_PAGE: ApplyServiceName("/acceptinvitation"),
  DEVEMAIL_BUILDER_PAGE: ApplyServiceName("/dev-email-builder"),
  TEST_PAGE: ApplyServiceName("/dev-test"),
  ABOUT_PAGE: ApplyServiceName("/about"),
};

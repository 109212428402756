import React, { CSSProperties } from "react";
import "./ImageAssetLoader.scss";

export interface ImageAssetLoaderProps {
  src?: string | null;
  title?: string;
  className?: string;
  style?: CSSProperties;
  alt?: string;
  onClick?: () => void;
  onError?: () => void;
}

export const GenerateAssetUrl = (src: string, cssUrl = false) => {
  if (!src || src.trim() === "") {
    return "";
  }

  if (
    src.startsWith("http") ||
    src.startsWith("https") ||
    src.startsWith("data:image") ||
    src.startsWith("blob:")
  ) {
    return src;
  }

  let final = "https://emanueleuniroma2.github.io/FFASP/S_M" + src;
  if (cssUrl) {
    final = "url('" + final + "')";
  }
  return final;
};

const ImageAssetLoader = (props: ImageAssetLoaderProps) => {
  const finalUrl: string | null = props.src
    ? GenerateAssetUrl(props.src)
    : null;
  return (
    <>
      {props.src && finalUrl && (
        <img
          onClick={props.onClick}
          onError={props.onError}
          src={finalUrl}
          alt={props.alt}
          title={props.title}
          className={props.className}
          style={props.style}
        />
      )}
    </>
  );
};

export default ImageAssetLoader;

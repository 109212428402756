export const SERVICE_NAME = "plotterstudio";

export const ApplyServiceName = (relPath: string) => {
  const notOnProxy =
    !window.location.origin.includes("portal-webui.azurewebsites.net") &&
    window.location.port !== "7105";

  if (notOnProxy) {
    return relPath;
  }
  return "/" + SERVICE_NAME + relPath;
};

import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import { Route, Routes } from "react-router-dom";
import EventPage from "./Pages/EventPage/EventPage";
import { AppRoutesMap, IAppRoute } from "./AppRoutesMap";
import Toolbar from "./Components/Toolbar/Toolbar";
import Tools from "./Utils/Tools";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "./Redux/RootReducer";
import ApiService from "./Services/ApiService";
import { IAPIResponse } from "./Services/AjaxService";
import { ToastMessage } from "./Utils/UIMessages";
import Tr from "./Utils/Translations/Translations";
import { GenericActions } from "./Redux/Generic/GenericAction";
import { loadEventReducer } from "./Utils/EventProcessing";
import { mobileCheck } from "./App";
import Footer from "./Components/Footer/Footer";
import { sys } from "typescript";
import { ISysAdminPublicMessage } from "./Models/ISysAdminPublicMessage";
import { MessageBar, MessageBarType } from "@fluentui/react";
import SysAdminBanner from "./Components/SysAdminBanner/SysAdminBanner";

function AppLayout() {
  const dispatch = useDispatch();
  const [routes, setRoutes] = useState<IAppRoute[]>([]);
  const [adminMessages, setAdminMessages] = useState<ISysAdminPublicMessage[]>(
    []
  );

  const loggedUser = useSelector((state: GlobalState) => state.generic.user);
  const globalevents = useSelector(
    (state: GlobalState) => state.generic.globalevents
  );

  const GetSysAdminMessages = () => {
    ApiService.SessionController.GetSysAdminMessages(
      (response: IAPIResponse) => {
        if (response.error === null) {
          setAdminMessages(response.parsed);
        }
      }
    );
  };

  const GetPurchases = () => {
    ApiService.StoreController.GetPurchases(
      undefined,
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(GenericActions.setPurchases(response.parsed));
        }
      }
    );
  };

  const GetSubscriptionFeatures = () => {
    ApiService.StoreController.GetSubscriptionFeatures(
      (response: IAPIResponse) => {
        if (response.error === null) {
          dispatch(GenericActions.setSubscriptionFeatures(response.parsed));
        }
      }
    );
  };

  const GetSubscriptions = () => {
    ApiService.StoreController.GetSubscriptions((response: IAPIResponse) => {
      if (response.error === null) {
        GetSubscriptionFeatures();
        dispatch(GenericActions.setSubscriptions(response.parsed));
      }
    });
  };

  const obtainUser = () => {
    ApiService.SessionController.GetSelfUser((response: IAPIResponse) => {
      if (response.error === null) {
        dispatch(GenericActions.setUser(response.parsed));
      }
    });
  };

  useEffect(() => {
    setRoutes(
      AppRoutesMap.filter(
        (x: IAppRoute) =>
          x.requiresLogin !== true || (x.requiresLogin && loggedUser)
      )
    );

    GetSubscriptions();
    GetSysAdminMessages();

    if (loggedUser) {
      GetPurchases();
    }
  }, [loggedUser]);

  useEffect(() => {
    if (globalevents.length > 0) {
      loadEventReducer(globalevents, dispatch);
    }
  }, [globalevents]);

  return (
    <div>
      <Toolbar />
      <div className="admin-messages-container">
        {adminMessages.map((x: ISysAdminPublicMessage, i: number) => {
          return <SysAdminBanner key={i} sysAdminPublicMessage={x} />;
        })}
      </div>
      <div className="page-under-toolbar-wrap">
        <Routes>
          {routes.map((x: IAppRoute, i: number) => {
            return (
              <Route key={i} path={x.path} element={<div>{x.element}</div>} />
            );
          })}
        </Routes>
      </div>
    </div>
  );
}

export default AppLayout;

export const fetchWithRetry = async (
  url: string,
  options: RequestInit = {},
  retries: number = 3,
  timeout: number = 45000,
  delay: number = 250,
): Promise<Response> => {
  for (let attempt = 0; attempt <= retries; attempt++) {
    // Create a fresh AbortController for each attempt.
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchOptions = { ...options, signal };

    // Function that performs a fetch with timeout handling.
    const fetchWithTimeout = (): Promise<Response> => {
      return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
          controller.abort();
          // On timeout, reject with a generic 500 error response.
          reject(new Response(null, { status: 500, statusText: "Timeout" }));
        }, timeout);

        fetch(url, fetchOptions)
          .then(resolve)
          .catch(reject)
          .finally(() => clearTimeout(timer));
      });
    };

    try {
      const response = await fetchWithTimeout();

      // If the response is OK or it's not a 500 server error range, return it immediately.
      if (response.ok || response.status < 500 || response.status >= 600) {
        return response;
      }

      // If it is a 500 error and we have retries left, wait before retrying.
      if (attempt < retries) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        continue;
      } else {
        // Retries exhausted: return the last response.
        return response;
      }
    } catch (error) {
      if (attempt < retries) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        continue;
      } else {
        // If the error is some kind of response (from our timeout rejection) or a fetch error.
        let errorResponse: Response;
        if (error instanceof Response) {
          errorResponse = error;
        } else {
          errorResponse = new Response(null, {
            status: 500,
            statusText: "Fetch error",
          });
        }
        return errorResponse;
      }
    }
  }
  // Fallback return for type safety (should never be reached)
  return new Response(null, { status: 500, statusText: "Unexpected error" });
};

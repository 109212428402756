import Tr from "../Utils/Translations/Translations";
import { getBaseUrl } from "./Env";
import { fetchWithRetry } from "./FetchWithTimeout";

interface APIItemPending {
  url: string;
  parameters: any;
  disableTimeout: boolean;
}

const GENERIC_ERROR: string = Tr.Translate("system", "generic_api_error");
const API_PIPELINE: any = {};
(window as any)["API_LOGS"] = [];

const getAPIQueueKey = (request: APIItemPending) => {
  return (
    (request.parameters.method ?? "GET") +
    ":" +
    request.url +
    ":" +
    (request.parameters.body ?? "")
  ).toLocaleLowerCase();
};

const FetchPipeline = async (
  url: string,
  parameters: any,
  disableTimeout: boolean
) => {
  let newItem: APIItemPending = {
    url,
    parameters,
    disableTimeout,
  };

  let apiKDescriptor = getAPIQueueKey(newItem);

  if (API_PIPELINE[apiKDescriptor]) {
    return null;
  }

  API_PIPELINE[apiKDescriptor] = newItem;

  await new Promise((r) => setTimeout(r, 500));

  let result = await fetchWithRetry(
    getBaseUrl() + newItem.url,
    newItem.parameters
  );

  delete API_PIPELINE[apiKDescriptor];

  return result;
};

const AjaxPipeline = {
  FetchPipeline,
};

export default AjaxPipeline;

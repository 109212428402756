import { SERVICE_NAME } from "../serviceName";

export const getBaseUrl = () => {
  const selfServiceLocalServer = window.location.origin;
  const remoteCloudProxy =
    "https://portal-webui.azurewebsites.net/" + SERVICE_NAME;
  const localCloudProxy = "http://localhost:7105/" + SERVICE_NAME;

  const localExecution =
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("127.0.0.1");

  const pickLocalServer =
    window.location.port === "7105" ? localCloudProxy : selfServiceLocalServer;

  const base = localExecution ? pickLocalServer : remoteCloudProxy;
  return base;
};
